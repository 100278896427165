<template>
    <div>
        <div class="loading-spinner" v-if="show">
            <div class="loading-spinner-div" style="margin-top:auto;height:100%">
                <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="60" :color="$root.colorLoading" />
            </div>
        </div>
        <b-card class="border-0 mt-40">
        
            <div class="ticket-header row">
                <div class="col-6">
                    <h4 class="fontWeightBold">تیکت ها</h4>
                </div>
                <div class="col-6 ticket-new text-left">
                    <b-button variant="light" v-b-modal.ticket-modal>
                        <span class="fontWeightBold">
                            ارسال تیکت جدید
                        </span>
                    </b-button>
                </div>
            </div>
            <div class="mt-5" v-if="items.data">
                <b-table responsive outlined hover class="ticket-table" :items="items.data.data" :fields="fields">
                    <template v-slot:cell(id)="data">
                        <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }">
                            {{data.item.id}}
                        </router-link>
                    </template>
                    <template v-slot:cell(title)="data">
                        <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }">
                            {{data.item.title}}
                        </router-link>
                    </template>
                    <template v-slot:cell(updated_at)="data">
                        <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }">
                            {{ data.item.updated_at | persianDate }}
                        </router-link>
                    </template>
                    <template v-slot:cell(status)="data">
                        <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }">
                            <p v-if="data.item.status == 'closed'" class="badge badge-danger p-2">
                                بسته شده
                            </p>
                            <p v-else-if="data.item.status == 'pending'" class="badge badge-warning p-2 text-light">
                                در حال انتظار
                            </p>
                            <p v-else-if="data.item.status == 'answered'" class="badge badge-success p-2">
                                پاسخ داده شده
                            </p>
                            <p v-else-if="data.item.status == 'submitted'" class="badge badge-primary p-2">
                                ثبت شده
                            </p>
                        </router-link>
                    </template>
                    <template v-slot:cell(show)="data">
                        <router-link :to="{ name: 'ticket.show', params: { id: data.item.id } }"
                            class="btn btn-info">
                            <i class="fa fa-eye" aria-hidden="true" style="font-size: 18px;"></i>
                        </router-link>
                    </template>
                </b-table>
                <pagination :limit="2" :data="items.data" @pagination-change-page="loadItems"></pagination>
                <div class="alert alert-danger" v-if="items.data.data == ''">
                    <span>موردی یافت نشد ! </span>
                </div>
            </div>
            <b-modal id="ticket-modal" hide-footer title="ثبت تیکت جدید">
                <form @submit.prevent="addTicket(), disabled = true" id="new-ticket">
                    <b-form-group>
                        <label for="">عنوان <span class="text-danger">*</span></label>
                        <b-form-input name="title" v-model="ticketTitle"></b-form-input>
                    </b-form-group>
                    <b-form-group>
                        <label for="">متن <span class="text-danger">*</span></label>
                        <b-form-textarea name="text" v-model="text" rows="5"></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="فایل" label-for="file">
                        <b-form-file id="file" name="files[]"></b-form-file>
                    </b-form-group>
                    <div class="text-center">
                        <b-button variant="success" type="submit" :disabled="disabled">ثبت تیکت</b-button>
                    </div>
                </form>
            </b-modal>
        </b-card>
    </div>
 
</template>
<script>
    import mixins from '../mixins/mixins.js'
    export default {
        mixins: [mixins],
        data() {
            return {
                url: '/api/seller/tickets',
                tickets: [],
                fields: [{
                        key: 'id',
                        label: 'شناسه'
                    },
                    {
                        key: 'title',
                        label: 'عنوان'
                    },
                    {
                        key: 'updated_at',
                        label: 'آخرین به روزرسانی'
                    },
                    {
                        key: 'status',
                        label: 'وضعیت'
                    },
                    {
                        key: 'show',
                        label: 'مشاهده'
                    },
                ],
                show: false,
                disabled: false,
                title: 'تیکت ها',
                ticketTitle:'',
                text: '',
            }
        },
        mounted() {
            this.loadItems()
        },
        methods: {
            loadTickets(page = 1) {
                this.show = true
                this.$axios.get(this.$root.baseUrl + this.url + `?page=` + page).then(response => {
                    this.tickets = response.data.data
                    this.show = false
                })
            },
            addTicket() {
                let form = document.getElementById('new-ticket')
                let formData = new FormData(form)
                this.$axios.post(this.$root.baseUrl + this.url, formData).then(response => {
                        this.disabled = false
                        this.ticketTitle = ''
                        this.text = ''
                        this.$root.success_notification(response.data.message)
                        this.$root.$emit('bv::hide::modal', 'ticket-modal')
                        this.items.data.data.unshift(response.data.data)

                    })
                    .catch(error => {
                        this.disabled = false
                        this.$root.error_notification(error)
                    })
            },
        }
    }
</script>
<style>
    .ticket-new .btn,
    .ticket-new .btn-light:focus {
        border-radius: 50px !important;
        border: 2px solid #555555 !important;
        /* box-shadow: unset !important; */
        background-color: #fff !important;
    }

    .ticket-new .btn-light:hover {
        border: 2px solid rgb(117, 112, 179) !important;
        background-color: #7570b3 !important;
        color: #fff !important;
    }

    #ticket-modal textarea {
        border-radius: 4px !important;
    }

    .ticket-table .btn {
        box-shadow: unset !important;
    }

    .ticket-table a:hover {
        color: #555 !important;
    }
</style>